import { ICheckResponse, ICheckRequest, ICheckDataBase, GateMode } from "./DataModel";
import { ApiService } from "./ApiService";
import { OfflineDataService } from "./OfflineDataService";
import { SettingsService } from "./SettingsService";
import { autoinject } from "aurelia-dependency-injection";
import { AppDb, CheckData } from "./AppDb";
import { TimeService } from "./TimeService";
import { uuid } from "@st/globster-data";
import { AppRouter } from "../AppRouter";

@autoinject()
export class GateService {
    constructor(
        private apiService: ApiService,
        private offlineDataService: OfflineDataService,
        private settingsService: SettingsService,
        private appDb: AppDb,
        private timeService: TimeService,
        private appRouter: AppRouter) {
    }

    async checkBadge(badgeId: string): Promise<ICheckResponse> {
        const requestBase: ICheckDataBase = {
            TickId: uuid(),
            DeviceId: this.settingsService.deviceId,
            BadgeId: badgeId,
            CheckType: this.settingsService.checkTypeId,
            GateId: this.settingsService.gateId,
            IsTestOnly: this.settingsService.isTestOnly,
        };
        const request: ICheckRequest = requestBase;

        let response: ICheckResponse = null;
        let isSynced = false;
        if (this.apiService.canSend && (this.settingsService.gateMode === GateMode.Online)) {
            request.HighResImage = this.settingsService.useHighResImages;
            try {
                response = await this.apiService.check(request);
                isSynced = true;
            } catch (err) {
                if (err instanceof SyntaxError) {
                    this.appRouter.Installation.navigator({}).navigate();
                }
                console.error(err);
            }
        }
        if (this.settingsService.gateMode === GateMode.Offline) {
            response = await this.offlineDataService.checkBadgeOffline(request);
        }
        if (!response) {
            throw new Error("there were no valid check response ...");
        }

        const item: CheckData = {
            TickId: request.TickId,
            Timestamp: this.timeService.getTimeIsoString(),
            BadgeId: badgeId,
            IsValid: response.IsValid,
            CheckType: request.CheckType,
            GateId: request.GateId,
            IsTestOnly: request.IsTestOnly,
            Message: response.Message,
            OperationActionForDeclinationReason: response.OperationActionForDeclinationReason,
            Images: response.Images,
            Titles: response.Titles,
            IsSync: isSynced,
            GeneratedDeclineReason: response.GeneratedDeclineReason,
            GatePassResponse: response.GatePassResponse,
        };
        if (!item.IsTestOnly) {
            await this.appDb.addNewCheck(item);
        }
        return response;
    }

}