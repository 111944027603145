export class TimeService {
    constructor(
    ) {

    }

    getTime(): Date {
        const d = new Date();
        d.setMilliseconds(0);
        return d;
    }

    getTimeIsoString(): string {
        return this.getTime().toISOString();
    }


    utcToLocal(utc: string): string {
        const date = new Date(utc);
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
        return `${date.toLocaleDateString("en-GB", {  day: "numeric", month: "short", year: "numeric" })} ${date.toLocaleTimeString("cs-cz", {hour: "numeric", minute: "2-digit"})}`;
    }

}


