import { observable } from "mobx";

export class EnumFlag {
    constructor(value: number = 0) {
        this.value = value;
    }

    @observable value: number;

    set(enumValue: number) {
        this.value = enumValue;
    }

    addFlag(enumValue: number) {
        this.value |= enumValue;
    }

    deleteFlag(enumValue: number) {
        this.value &= ~enumValue;
    }

    hasFlag(enumValue: number): boolean {
        return (this.value & enumValue) === enumValue;
    }
}