import React from "react";
import QrReader from "react-qr-reader";
import { autoinject } from "aurelia-dependency-injection";
import { SettingsService } from "../_Services/SettingsService";
import { AppBar, Backdrop, CircularProgress, IconButton, LinearProgress, Toolbar, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { SideMenu, SideMenuModel } from "../_Components/SideMenu";
import { action, observable, computed } from "mobx";
import { ICheckResponse, GateMode, ErrorState } from "../_Services/DataModel";
import { NativeAppService } from "../_Services/NativeAppService";
import { ScanCard } from "../_Components/ScanCard";
import { Spring } from "react-spring/renderprops.cjs";
import { GateService } from "../_Services/GateService";
import { AppDb } from "../_Services/AppDb";
import { ApiService } from "../_Services/ApiService";
import { AppModel } from "../AppModel";
import { LanguageSelector } from "../_Components/LanguageSelector";
import { ActionSwitcher } from "../_Components/ActionSwitcher";
import { uuid } from "@st/globster-data";

import ReadyToScanIcon from "@material-ui/icons/AspectRatio";
import IconMenu from "@material-ui/icons/Menu";
import OfflineIcon from "@material-ui/icons/WifiOff";
import OfflineGateIcon from "@material-ui/icons/CloudDownload";
import WifiIcon from "@material-ui/icons/Wifi";
import StorageIcon from "@material-ui/icons/Storage";
import AutoModeIcon from '@material-ui/icons/BrightnessAuto';
import ClockIcon from "@material-ui/icons/AccessAlarm";
import OfflineModeIcon from "@material-ui/icons/Cloud";

export interface ScanCardInfo {
    id: string;
    checkInfo: ICheckResponse;
    code: string;
    isPreviewOpen: boolean;
}

@autoinject()
export class ScanPageModel {
    constructor(
        public settingsService: SettingsService,
        private gateService: GateService,
        nativeAppService: NativeAppService,
        private appDb: AppDb,
        private apiService: ApiService) {

        nativeAppService.nfcScanned.subscribe(code => {
            this.scan(code);
        });

        appDb.getOrderedChecksDesc().then(history => {
            const lastCheckData = history[0];

            if (!!lastCheckData) {
                const lastScanCardInfo: ScanCardInfo = {
                    id: uuid(),
                    checkInfo: lastCheckData,
                    code: lastCheckData.BadgeId,
                    isPreviewOpen: false
                };

                this.scanCardsInfo.push(lastScanCardInfo);
            }
        });
    }

    @observable scanCardsInfo: ScanCardInfo[] = [];
    @observable IsScanAvailable: boolean = true;
    @observable isLoading: boolean = false;
    @observable isError: boolean = false;

    @action.bound changeDirection() {
        const directions = this.settingsService.getAllowedCheckTypes();
        const checkTypeId = this.settingsService.checkTypeId;
        let idx = 0;

        if (checkTypeId) {
            idx = (directions.findIndex(i => i.value === checkTypeId) + 1);
        }

        if (directions && idx >= directions.length) {
            idx = 0;
        }

        this.settingsService.checkTypeId = directions[idx].value;
    }

    @action async scan(code: string) {
        const lastScanCardIndex = this.scanCardsInfo.length - 1;

        // allow same scan codes only if not locked or first scan
        if (code !== this.scanCardsInfo[lastScanCardIndex]?.code || this.IsScanAvailable) {
            this.isLoading = true;
            this.IsScanAvailable = false;

            try {
                const response = await this.gateService.checkBadge(code);
                const validationTimeout = response.IsValid
                    ? this.settingsService.scanOnScreenTimeout
                    : this.settingsService.errorOnScreenTimeout;

                const currentScanCardInfo: ScanCardInfo = {
                    id: uuid(),
                    checkInfo: response,
                    code: code,
                    isPreviewOpen: true
                };
                this.scanCardsInfo.push(currentScanCardInfo);

                this.isError = false;
                this.refreshLockTimeout();

                setTimeout(() => {
                    const scanCardToHideIndex = this.scanCardsInfo.findIndex(i => i.id === currentScanCardInfo.id);
                    if (scanCardToHideIndex >= 0) {
                        this.scanCardsInfo[scanCardToHideIndex].isPreviewOpen = false;
                    }
                }, validationTimeout);
            } catch (e) {
                this.isError = true;
                this.IsScanAvailable = true;
                this.scanCardsInfo = this.scanCardsInfo.map(i => ({ ...i, isPreviewOpen: false }));

                setTimeout(() => {
                    this.isError = false;
                }, this.settingsService.errorOnScreenTimeout);
            } finally {
                this.isLoading = false;
            }
        } else {
            this.refreshLockTimeout();
        }
    }

    private cancelTimeout: () => void = () => { };

    private refreshLockTimeout() {
        this.cancelTimeout();
        const timer = setTimeout(() => {
            this.IsScanAvailable = true;
        }, this.settingsService.scanningLockTimeout);
        this.cancelTimeout = () => clearTimeout(timer);
    }

    @computed get isNoConnection(): boolean {
        return this.settingsService.gateMode === GateMode.Online && !this.apiService.canSend;
    }

    @computed get isDbWarning(): boolean {
        return this.appDb.dbQuotaDisplayWarning;
    }

    @computed get isOfflineSupported(): boolean {
        return this.settingsService.gate && this.settingsService.gate.IsOfflineModeSupported;
    }
}

export interface ScanPageProps {
    model: ScanPageModel;
    menuModel: SideMenuModel;
    appModel: AppModel;
}

@observer
export class ScanPage extends React.Component<ScanPageProps> {
    constructor(props: ScanPageProps, context: any) {
        super(props, context);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.requestWakeLock = this.requestWakeLock.bind(this);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);

        if ("wakeLock" in navigator && !this.props.model.settingsService.isCameraScanningEnabled) {
            this.requestWakeLock();
            document.addEventListener("visibilitychange", this.handleVisibilityChange);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);

        if (this.wakeLock) {
            document.removeEventListener("visibilitychange", this.handleVisibilityChange);
            this.wakeLock.release();
            this.wakeLock = null;
        }
    }

    private buffer: string = "";
    private wakeLock: any = null;

    @action.bound private handleCameraScan = data => {
        if (data) {
            this.props.model.scan(data);
        }
    }

    @action.bound private handleCameraError = err => {
        console.error(err);
    }

    handleKeyDown(e: KeyboardEvent) {
        if (this.props.appModel.appState.globalError.value) {
            this.buffer = "";
            return;
        }

        if (e.keyCode === 27) {
            this.buffer = "";
        }

        if (e.keyCode === 13 && this.buffer) {
            this.props.model.scan(this.buffer);
            this.buffer = "";
        }

        if (e.key && e.key.length === 1 && /[a-z,A-Z,0-9]/.test(e.key)) {
            this.buffer = this.buffer + e.key;
        }
    }

    getCardAnimationMaxHeight(isPreviewOpen: boolean) {
        if (isPreviewOpen) {
            return { maxHeight: window.innerHeight };
        }
        return { maxHeight: 130 };
    }

    getCardAnimationConfiguration(isAnimationEnabled: boolean) {
        if (isAnimationEnabled) {
            return { tension: 1200, friction: 80, precision: 0.1 };
        }
        return { tension: 0, friction: 10, precision: 0.1 };
    }

    toggleCardPreview(badge: ScanCardInfo) {
        badge.isPreviewOpen = !badge.isPreviewOpen;
    }

    async requestWakeLock() {
        try {
            this.wakeLock = await (window.navigator as any).wakeLock.request("screen");
        } catch (err) {
            console.error(`${err.name}, ${err.message}`);
        }
    }

    async handleVisibilityChange() {
        if (this.wakeLock !== null && document.visibilityState === "visible") {
            await this.requestWakeLock();
        }
    }

    render() {
        const { model, menuModel, appModel } = this.props;

        return <div className="mdl-layout mdl-js-layout mdl-layout--fixed-header mdl-layout--no-drawer-button full-height">
            <SideMenu model={menuModel} />
            <AppBar position="static">
                <Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <IconButton color="inherit" aria-label="Menu" onClick={_ => menuModel.isMenuOpen = true}>
                        <IconMenu />
                    </IconButton>
                    <div className="toolbar-statuses">
                        {
                            model.settingsService.showGateType &&
                            <>
                                {
                                    model.isOfflineSupported
                                        ? <OfflineGateIcon />
                                        : <WifiIcon />
                                }
                            </>
                        }
                        {
                            model.isOfflineSupported &&
                            model.settingsService.isAutomativeMode &&
                            <AutoModeIcon className="blink-anim" />
                        }
                        {
                            model.isDbWarning && <StorageIcon className="blink-anim" />
                        }
                    </div>
                    {
                        model.settingsService.gate &&
                        <Typography variant="h6" color="inherit">
                            {
                                model.settingsService.gate.Name
                            }
                        </Typography>
                    }
                    <div className={"toolbar-switchers"}>
                        {
                            model.settingsService.showLanguageSelector && <LanguageSelector />
                        }
                        {
                            model.settingsService.showScanningMode &&
                            <ActionSwitcher settingsService={model.settingsService} onClick={() => model.changeDirection()} />
                        }
                    </div>
                </Toolbar>
            </AppBar>
            {
                model.isLoading
                    ? <LinearProgress />
                    : <div className="progress-space" />
            }
            <div className={`status-icons-wrapper ${model.settingsService.isCameraScanningEnabled && "small"}`}>
                {
                    appModel.appState.globalError.value !== ErrorState.None
                        ? <div className="status-icons error" >
                            {
                                appModel.appState.globalError.hasFlag(ErrorState.TimeNotSynced) &&
                                <ClockIcon className="container-big blink-anim" />
                            }
                            {
                                appModel.appState.globalError.hasFlag(ErrorState.DbQuotaReached) &&
                                <StorageIcon className="container-big blink-anim" />
                            }
                        </div>
                        : <div className="status-icons" >
                            {
                                model.isLoading && <CircularProgress className="container-big" />
                            }
                            {
                                !model.isLoading && !model.isError && !model.isNoConnection &&
                                <ReadyToScanIcon className="container-big puls-anim" />
                            }
                            {
                                (model.isError || model.isNoConnection) &&
                                <OfflineIcon className="container-big blink-anim" />
                            }
                            {
                                model.settingsService.gateMode === GateMode.Offline &&
                                <OfflineModeIcon className="container-big puls-anim" />
                            }
                        </div>
                }
            </div>
            {
                model.settingsService.showEventName &&
                <div className="mdl-layout__content">
                    <div className="layout-form">
                        {
                            model.settingsService.homeImages.map((i, idx) => <img key={idx} src={i} />)
                        }
                        {
                            model.settingsService.homeTitles.map((i, idx) => <div className="title" key={idx}>{i}</div>)
                        }
                    </div>
                </div>
            }
            {
                model.settingsService.isCameraScanningEnabled &&
                <div className="camera-wrapper">
                    <QrReader
                        delay={300}
                        onError={this.handleCameraError}
                        onScan={this.handleCameraScan}
                        style={{ width: "100%" }}
                    />
                </div>
            }
            <div className="cards-container">
                {
                    model.scanCardsInfo.map((info, index) =>
                        <Spring
                            key={`preview-${info.code}-${index}`}
                            to={this.getCardAnimationMaxHeight(info.isPreviewOpen)}
                            config={this.getCardAnimationConfiguration(model.settingsService.isAnimationEnabled)}
                        >
                            {
                                props => <>
                                    {
                                        info.isPreviewOpen &&
                                        <Backdrop
                                            open={info.isPreviewOpen}
                                            onClick={_ => this.toggleCardPreview(info)}
                                            style={{ zIndex: 1200, transition: "0" }}
                                        />
                                    }
                                    <ScanCard
                                        getResponse={() => info.checkInfo}
                                        className="card-preview"
                                        settingsService={model.settingsService}
                                        headerClick={_ => this.toggleCardPreview(info)}
                                        close={() => this.toggleCardPreview(info)}
                                        style={{ maxHeight: props.maxHeight }}
                                    />
                                </>
                            }
                        </Spring>
                    )
                }
            </div>
        </div>;
    }
}