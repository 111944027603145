import * as React from "react";
import { Component } from "react";
import { observer } from "mobx-react";

export function once(
    mountAction: (props) => any,
    unmountAction: (props) => any = null
): (Comp) => any {
    return Comp => {
        function getDisplayName(WrappedComponent) {
            return WrappedComponent.displayName || WrappedComponent.name || "Component";
        }

        @observer
        class OnceContainer extends Component<any, any> {
            static displayName = `Once(${getDisplayName(Comp)})`;

            constructor(props: any, context: any) {
                super(props, context);
                const newProps = mountAction(props);
                this.state = { newProps };
            }

            componentWillUnmount() {
                if (unmountAction) {
                    unmountAction({ ...this.props, ...this.state.newProps });
                }
            }

            render() {
                const props = this.props;
                return <Comp {...props} {...this.state.newProps} />;
            }
        }

        return OnceContainer;
    };
}
