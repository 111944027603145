import React from "react";
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
import IconKeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

export interface LayoutProps {
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

export const Layout = (props: LayoutProps) => {
    return <div
        className={`mdl-layout mdl-js-layout mdl-layout--fixed-header mdl-layout--no-drawer-button ${props.className || ""}`}
        style={props.style}>
        {props.children}
    </div>;
};

export interface LayoutHeaderProps {
    title: string;
    isBackDisabled?: boolean;
    goBack?: () => void;
    children?: any;
}

export const LayoutHeader = (props: LayoutHeaderProps) => {
    const { title, goBack, isBackDisabled } = props;
    return <AppBar position="sticky">
        <Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
            {goBack && <IconButton color="inherit" aria-label="Menu" onClick={goBack} disabled={isBackDisabled}>
                <IconKeyboardArrowLeft />
            </IconButton>}
            <Typography variant="h6" color="inherit">
                <span className="main-title">{title}</span>
            </Typography>
            {props.children}
        </Toolbar>
    </AppBar>;

};

export interface LayoutContentProps {
    children?: React.ReactNode;
}

export const LayoutContent = (props: LayoutContentProps) => {
    return <main className="mdl-layout__content">{props.children}</main>;
};


