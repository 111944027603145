import React from "react";
import { observer } from "mobx-react";
import { CheckType } from "../_Services/DataModel";
import { r } from "../_Services/LanguageService";
import { SettingsService } from "../_Services/SettingsService";

export interface ActionSwitcherProps {
    settingsService: SettingsService;
    onClick: () => void;
}

@observer
export class ActionSwitcher extends React.Component<ActionSwitcherProps> {
    render() {
        const { settingsService, onClick } = this.props;

        return (
            <>
                {
                    settingsService.checkIsSeparatedOne &&
                    <div className="switch-container">
                        <div
                            className={`switch-item switch-single selected`}
                            onClick={() => onClick()}
                        >
                            {r(settingsService.checkType.title)}
                        </div>
                    </div>
                }
                {
                    settingsService.checkIsInOut &&
                    <div className="switch-container">
                        <div
                            className={`switch-item switch-in ${settingsService.checkTypeId === CheckType.In ? "selected" : ""}`}
                            onClick={() => onClick()}
                        >
                            {r("In")}
                        </div>
                        <div
                            className={`switch-item switch-out with-in ${settingsService.checkTypeId === CheckType.Out ? "selected" : ""}`}
                            onClick={() => onClick()}
                        >
                            {r("Out")}
                        </div>
                    </div>
                }
                {
                    settingsService.checkIsAutoInOut &&
                    <div className="switch-container">
                        <div
                            className={`switch-item switch-in ${settingsService.checkTypeId === CheckType.In ? "selected" : ""}`}
                            onClick={() => onClick()}
                        >
                            {r("In")}
                        </div>
                        <div
                            className={`switch-item switch-out ${settingsService.checkTypeId === CheckType.Out ? "selected" : ""}`}
                            onClick={() => onClick()}
                        >
                            {r("Out")}
                        </div>
                        <div
                            className={`switch-item switch-auto ${settingsService.checkTypeId === CheckType.Auto ? "selected" : ""}`}
                            onClick={() => onClick()}
                        >
                            {r("Auto")}
                        </div>
                    </div>
                }
            </>
        );
    }
}
