import React from "react";
import { autoinject } from "aurelia-dependency-injection";
import { action, observable } from "mobx";
import { AppRouter } from "../AppRouter";
import { Layout, LayoutHeader, LayoutContent } from "../_Components/Layout";
import { List, ListItem, ListItemText, ListItemIcon, Backdrop } from "@material-ui/core";
import { observer } from "mobx-react";
import { ScanCard } from "../_Components/ScanCard";
import { CheckData, AppDb } from "../_Services/AppDb";
import { TimeService } from "../_Services/TimeService";
import { CheckType, GatePassDirection } from "../_Services/DataModel";
import { r } from "../_Services/LanguageService";
import IconValid from "@material-ui/icons/CheckCircle";
import IconInValid from "@material-ui/icons/RemoveCircle";
import IconNotSynced from "@material-ui/icons/CloudOff";
import IconSynced from "@material-ui/icons/CloudDone";
import OutIcon from "@material-ui/icons/Rotate90DegreesCcw";
import InIcon from "@material-ui/icons/ExitToApp";
import { SettingsService } from "../_Services/SettingsService";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { once } from "../_Common/once";

@autoinject()
export class HistoryPageModel {
    constructor(
        public settingsService: SettingsService,
        private appRouter: AppRouter,
        private appDb: AppDb,
        private timeService: TimeService) {
    }

    @observable items: CheckData[] = [];
    @observable selectedItem: CheckData = null;

    @action.bound async load() {
        this.items = await this.appDb.getOrderedChecksDesc();
    }

    @action selectItem(item: CheckData) {
        this.selectedItem = item;
    }

    @action.bound closeSelectedItem() {
        this.selectedItem = null;
    }

    @action.bound goBack() {
        this.appRouter.navigateHome();
    }

    getPrimaryTitle(item: CheckData): string {
        return `${this.timeService.utcToLocal(item.Timestamp)} | ${this.getTitle(item, 0)} (${item.BadgeId})`;
    }

    getSecondaryTitle(item: CheckData): string {
        if (item.Message) {
            return item.Message;
        }

        if (item.Titles) {
            const titles = item.Titles.filter((i, idx) => idx !== 0 && !!i);
            return titles.join(" ");
        }
        return "";
    }

    getTitle(item: CheckData, titleIndex: number, defaultValue: string = "?"): string {
        if (item.Titles && item.Titles.length > titleIndex) {
            return item.Titles[titleIndex] ?? "---";
        }
        return defaultValue;
    }

}

export interface HistoryPageProps {
    model: HistoryPageModel;
}

@once((props: HistoryPageProps) => {
    props.model.load();
})
@observer
export class HistoryPage extends React.Component<HistoryPageProps> {
    render() {
        const { model } = this.props;
        return <Layout>
            <LayoutHeader title={r("Scanning History")} goBack={model.goBack} />
            <LayoutContent>
                <List component="nav">
                    {
                        model.items.map(i =>
                            <ListItem key={i.TickId} button >
                                <ListItemIcon>
                                    {i.IsValid ? <IconValid className="icon-valid" /> : <IconInValid className="icon-invalid" />}
                                </ListItemIcon>
                                <ListItemIcon>
                                    {i.IsSync ? <IconSynced className="icon-valid" /> : <IconNotSynced className="icon-invalid" />}
                                </ListItemIcon>
                                {(i.CheckType === CheckType.In || i.CheckType === CheckType.Out || i.CheckType === CheckType.Auto) &&
                                    <ListItemIcon>
                                        {i.GatePassResponse === GatePassDirection.In || i.CheckType === CheckType.In ? <InIcon className="icon-valid" /> : <OutIcon className="icon-valid" />}
                                    </ListItemIcon>}
                                <ListItemIcon onClick={_ => model.selectItem(i)}>
                                    <AssignmentIndIcon className={`icon-${i.IsValid ? `valid` : `invalid`}`} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={model.getPrimaryTitle(i)}
                                    secondary={model.getSecondaryTitle(i)}
                                />
                            </ListItem>
                        )}
                </List>
            </LayoutContent>
            {
                model.selectedItem &&
                <>
                    <Backdrop open={true} style={{ zIndex: 1200, transition: "0" }} />
                    <ScanCard
                        className="history-card-dialog"
                        getResponse={() => model.selectedItem}
                        headerClick={model.closeSelectedItem}
                        close={model.closeSelectedItem}
                        settingsService={model.settingsService}
                    />
                </>
            }
        </Layout>;
    }
}