import React, { } from "react";
import { autoinject } from "aurelia-dependency-injection";
import { SettingsService } from "../_Services/SettingsService";
import { OfflineDataService } from "../_Services/OfflineDataService";
import { LayoutContent, Layout, LayoutHeader } from "../_Components/Layout";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import LinearProgress, { } from "@material-ui/core/LinearProgress";
import { DbQuotaInfo } from "../_Services/DataModel";
import { observer } from "mobx-react";
import { observable, action, computed } from "mobx";
import { AppRouter } from "../AppRouter";
import { r } from "../_Services/LanguageService";
import { TimeService } from "../_Services/TimeService";
import { AppDb } from "../_Services/AppDb";
import { ApiService } from "../_Services/ApiService";
import { AppModel } from "../AppModel";
import DeleteIcon from "@material-ui/icons/Delete";
import EnterIcon from "@material-ui/icons/AssignmentTurnedIn";
import OfflineIcon from "@material-ui/icons/WifiOff";
import { AppState } from "../AppState";
import { once } from "../_Common/once";
import { confirmAction } from "../_Common/helpers/actionHelper";


@autoinject()
export class OfflineModePageModel {
    constructor(
        private settingsService: SettingsService,
        private offlineDataService: OfflineDataService,
        private appRouter: AppRouter,
        private timeService: TimeService,
        private appDb: AppDb,
        private apiService: ApiService,
        private appState: AppState) {
    }

    @observable pullNewBadgesOnSync: boolean;
    @observable requireSuPassword: boolean = true;
    @observable automaticPhotoDownload: boolean;

    @observable modalIsOpen: boolean = false;
    @observable showSuSettings: boolean = false;


    @observable pass: string = "";

    @computed get dbQuotas(): DbQuotaInfo {
        return this.appDb.dbQuotas;
    }

    @computed get badgePhotosDownloaded(): number {
        return this.offlineDataService.photosTotalStored;
    }

    @computed get badgePhotosRequired(): number {
        return this.offlineDataService.photosTotalRequired;
    }

    @computed get offlineDataVersion(): string {
        if (!this.offlineDataService.dataVersion) {
            return null;
        }
        return `${this.timeService.utcToLocal(this.offlineDataService.dataUpdateTime)} (${this.offlineDataService.dataVersion})`;
    }

    @computed get canDownload(): boolean {
        return this.apiService.canSend;
    }

    @action async downloadOfflineBadges(force: boolean) {
        const updated = await this.offlineDataService.downloadOfflineBadges(force);
        if (updated === null) {
            alert(r("Update Failed"));
        } else {
            alert(`${r("Number of downloaded Badges:")} ${updated}`);
        }
    }

    @action downloadOfflinePhotos() {
        this.offlineDataService.downloadOfflinePhotos();
    }

    @action deleteOfflineData() {
        this.offlineDataService.deleteOfflineData();
    }

    @action deleteStoredPhotos() {
        this.offlineDataService.clearAllPhotos();
    }

    @action clearCheckHistory(clearSyncedOnly: boolean) {
        this.offlineDataService.clearHistory(clearSyncedOnly);
    }

    async getCheckHistoryCount(syndedOnly: boolean) {
        return await this.appDb.getCheckCount(syndedOnly);
    }

    @computed get ItemsCountToSynchronize(): number | null {
        return this.appDb.itemsToSynchronize;
    }

    @computed get ItemsCountSynchronized(): number | null {
        return this.appDb.itemsSynchronized;
    }

    @computed get ItemsCountTitle(): string {
        return `${this.ItemsCountSynchronized}/${this.ItemsCountTotal} (${this.ItemsCountToSynchronize}`;
    }

    @computed get ItemsCountTotal(): number {
        return this.ItemsCountSynchronized + this.ItemsCountToSynchronize;
    }

    @computed get photosDownloadInProgress(): boolean {
        return this.offlineDataService.photosDownloadInProgess;
    }

    @computed get badgesInDb(): number {
        return this.offlineDataService.totalBadges;
    }

    @action requestSu() {
        if (this.requireSuPassword) {
            this.modalIsOpen = true;
        } else {
            this.showSuSettings = true;
        }
    }

    @action async suInputValueChanged(value: string) {
        if (await this.appState.checkSuPassword(value)) {
            this.modalIsOpen = false;
            this.showSuSettings = true;
        }
    }

    @action.bound apply(): void {
        this.settingsService.pullNewBadgesOnSync = this.pullNewBadgesOnSync;
        this.settingsService.requireSuPassword = this.requireSuPassword;
        this.settingsService.automaticPhotoDownload = this.automaticPhotoDownload;

        this.appRouter.Scan.navigator({}).navigate();
    }

    @action load() {
        this.showSuSettings = false;
        this.pass = "";
        this.pullNewBadgesOnSync = this.settingsService.pullNewBadgesOnSync;
        this.requireSuPassword = this.settingsService.requireSuPassword;
        this.automaticPhotoDownload = this.settingsService.automaticPhotoDownload;
    }

    async deleteChecks(clearSyncedOnly: boolean) {
        const description = `${r("Are you sure?")} (${await this.getCheckHistoryCount(clearSyncedOnly)} ${r("to delete")})`;
        return confirmAction(() => this.clearCheckHistory(clearSyncedOnly), description);
    }
}

export interface OfflineModePageProps {
    model: OfflineModePageModel;
    appModel: AppModel;
}

@once((props: OfflineModePageProps) => {
    props.model.load();
})
@observer
export class OfflineModePage extends React.Component<OfflineModePageProps> {
    render() {
        const { model } = this.props;

        return <Layout>
            <LayoutHeader title={r("Offline Mode")} goBack={model.apply} />
            <LayoutContent>
                <div className="simple-form form-controls">
                    <FormControl>
                        <FormLabel >
                            {r("Last Sync Data (version)")}
                        </FormLabel>
                        <>{model.offlineDataVersion}</>
                    </FormControl>
                    <FormControl>
                        <FormLabel >
                            {r("Badges")}
                        </FormLabel>
                        <>{model.badgesInDb}</>
                    </FormControl>
                    <FormControl>
                        <FormLabel >
                            {r("Photos")}
                        </FormLabel>
                        <>{model.badgePhotosDownloaded}/{model.badgePhotosRequired}</>
                    </FormControl>
                    <FormControl>
                        <FormLabel >
                            {r("Gate Checks")}
                        </FormLabel>
                        <> {model.ItemsCountTitle} {r("to be uploaded")})</>
                    </FormControl>
                    {!!model.showSuSettings && <div className="offline-data-container">
                        <FormControl>
                            <FormLabel htmlFor="isPullOnSyncenabledBadge">{r("Auto-Pull Badges")}</FormLabel>
                            <Switch
                                name="isPullOnSyncenabledBadge"
                                checked={model.pullNewBadgesOnSync}
                                onChange={e => model.pullNewBadgesOnSync = e.target.checked}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="isPullOnSyncenabledPhoto">{r("Auto-Pull Photos")}</FormLabel>
                            <Switch
                                name="isPullOnSyncenabledPhoto"
                                checked={model.automaticPhotoDownload}
                                onChange={e => model.automaticPhotoDownload = e.target.checked}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="downloadOfflineBadges">
                                {(!model.canDownload) && <OfflineIcon className="blink-anim"></OfflineIcon>}
                            </FormLabel>
                            <Button className="offline-mode-button" variant="contained" color="primary"
                                disabled={(!model.canDownload)}
                                name="downloadOfflineBadges"
                                onClick={_ => model.downloadOfflineBadges(false)}>
                                {r("Download Offline Badge Data")}
                            </Button>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="downloadOfflineBadgesForce">
                                {(!model.canDownload) && <OfflineIcon className="blink-anim"></OfflineIcon>}
                            </FormLabel>
                            <Button className="offline-mode-button" variant="contained" color="primary"
                                disabled={(!model.canDownload)}
                                name="downloadOfflineBadgesForce"
                                onClick={_ => model.downloadOfflineBadges(true)}>
                                {r("Force-Download Offline Badge Data")}
                            </Button>
                        </FormControl>
                        <FormControl disabled={model.photosDownloadInProgress || (model.badgePhotosDownloaded === model.badgePhotosRequired)}>
                            <FormLabel htmlFor="downloadOfflinePhotos">
                                {(!model.canDownload) && <OfflineIcon className="blink-anim"></OfflineIcon>}
                            </FormLabel>
                            <Button className="offline-mode-button"
                                disabled={(!model.canDownload) || model.photosDownloadInProgress || (model.badgePhotosDownloaded === model.badgePhotosRequired)}
                                variant="contained"
                                color="primary"
                                name="downloadOfflinePhotos"
                                onClick={_ => model.downloadOfflinePhotos()}>
                                {r("Download Offline Photos")}
                            </Button>
                        </FormControl>
                        <FormControl>
                            <Button
                                className="offline-mode-button"
                                variant="contained"
                                color="secondary"
                                name="deleteOfflineData"

                                onClick={() => confirmAction(() => model.deleteOfflineData())}>
                                {r("Delete Offline Data")}
                            </Button>
                        </FormControl>
                        <FormControl>
                            <Button
                                className="offline-mode-button"
                                variant="contained"
                                color="secondary"
                                name="deleteStoredPhotos"
                                onClick={() => confirmAction(() => model.deleteStoredPhotos())}>
                                {r("Delete Offline Photos")}
                            </Button>
                        </FormControl>
                        <FormControl>
                            <Button
                                className="offline-mode-button" variant="contained"
                                color="secondary"
                                name="deleteHistory"
                                onClick={async () => model.deleteChecks(true)}>
                                {r("Delete Scanning History (only synced)")}
                            </Button>
                        </FormControl>
                        <FormControl>
                            <Button
                                className="offline-mode-button" variant="contained"
                                color="secondary"
                                name="deleteHistoryWithNotSynchronized"
                                onClick={async () => model.deleteChecks(false)}
                            >
                                {r("Delete Scanning History (including non-synced)")}
                            </Button>
                        </FormControl>
                        <FormControl>
                            <FormLabel >
                                {`${r("Used Storage:")} ${model.dbQuotas.Percentage}% - ${model.dbQuotas.Usage}/${model.dbQuotas.Quota}(${model.dbQuotas.Units})`}
                            </FormLabel>
                            <LinearProgress color={model.dbQuotas.Percentage > 80 ? "secondary" : "primary"} variant="determinate" value={model.dbQuotas.Percentage} />
                        </FormControl>
                    </div>}
                    {!model.showSuSettings && <FormControl >
                        <Button variant="contained" color="primary"
                            name="modalOpen"
                            onClick={_ => model.requestSu()}
                        >
                            {r("Advanced Settings >>")}
                        </Button>
                    </FormControl>}
                    <Modal
                        open={model.modalIsOpen}
                        onClose={e => model.modalIsOpen = false}>
                        <div className="settings-modal-wrapper">
                            <div className="settings-modal">
                                <h2 >{r("Enter Password")}</h2>
                                <input type="text" pattern="\d*"
                                    value={model.pass} className="text-security"
                                    onChange={e => {
                                        model.pass = e.target.value;
                                    }}
                                    onKeyDown={e => {
                                        if (e.key === "Enter") {
                                            model.suInputValueChanged(model.pass);
                                        }
                                    }}
                                ></input>
                                <div className="number-dial">
                                    {Array.from(Array(10).keys()).map(i =>
                                        <Button
                                            key={`btn_${i}`}
                                            variant="contained"
                                            color="primary"
                                            onClick={_ => {
                                                model.pass = (`${model.pass}${i}`);
                                            }}
                                        >
                                            {i}
                                        </Button>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={_ => model.pass = ""}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={_ => model.suInputValueChanged(model.pass)}
                                    >
                                        <EnterIcon />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </LayoutContent>
        </Layout>;
    }
}