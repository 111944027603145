/**
 * This is just abstraction for retreiving Storage through DI container.
 */
export class DataStorage {
    constructor(private storage: Storage = localStorage) {
    }

    getItem(key: string): string {
        return this.storage.getItem(key);
    }

    setItem(key: string, value: string): void {
        this.storage.setItem(key, value);
    }


    setObject<T>(key: string, obj: T): void {
        this.storage.setItem(
            key,
            JSON.stringify(obj)
        );
    }

    getObject<T>(key: string): T | null {
        const objJson = this.storage.getItem(key);
        if (objJson) {
            return JSON.parse(objJson) as T;
        }
        return null;
    }


}