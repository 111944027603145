import { DeclineReason } from "../../_Services/DataModel";

export function getCompressedTextSize(text: string, textContainerMaxWidth: number, fontSize: string = "2em", minFontSize: string = "1.3em"): string {
    let size: number = parseFloat(fontSize);
    const unit: string = fontSize.replace(`${size}`, "");
    const fontSizeStep = getSizeStepByUnit(unit);

    const body = document.getElementsByTagName("body")[0];
    const fakeDiv = document.createElement("fakeDiv") as HTMLDivElement;

    fakeDiv.style.visibility = "none";
    fakeDiv.style.position = "absolute";
    fakeDiv.style.fontSize = fontSize;
    fakeDiv.innerHTML = text;
    body.appendChild(fakeDiv);

    while (fakeDiv.offsetWidth >= body.offsetWidth * textContainerMaxWidth) {
        if (size > parseFloat(minFontSize)) {
            size -= fontSizeStep;
            fakeDiv.style.fontSize = `${size}${unit}`;
        } else break;
    }
    body.removeChild(fakeDiv);

    return `${size}${unit}`;
}

function getSizeStepByUnit(unit: string): number {
    switch (unit) {
        case "px":
            return 1;
        case "em":
        default:
            return 0.1;
    }
}

export function getDeclineReasonString(val: DeclineReason): string {
    if (!val) return "";
    return DeclineReason[val].replace(/([A-Z])/g, " $1").trim();
}