import { autoinject } from "aurelia-dependency-injection";
import { OnlineService } from "./_Services/OnlineService";
import { ApiService } from "./_Services/ApiService";
import { computed } from "mobx";
import { SettingsService } from "./_Services/SettingsService";
import { HeartbeatService } from "./_Services/HeartbeatService";
import { InstallationPageModel } from "./Pages/InstallationPage";
import { ScanPageModel } from "./Pages/ScanPage";
import { SettingsPageModel } from "./Pages/SettingsPage";
import { AppRouter } from "./AppRouter";
import { Route } from "@st/globster-router";
import { SideMenuModel } from "./_Components/SideMenu";
import { HistoryPageModel } from "./Pages/HistoryPage";
import { OfflineDataService } from "./_Services/OfflineDataService";
import { TimeService } from "./_Services/TimeService";
import { AppState } from "./AppState";
import { OfflineModePageModel } from "./Pages/OfflineModePage";

export interface AppThemeOptions {
    isAnimationEnabled: boolean;
    isTestMode: boolean;
    isLandscapeMode: boolean;
}

@autoinject()
export class AppModel {
    constructor(
        public onlineService: OnlineService,
        private apiService: ApiService,
        private settingsService: SettingsService,
        private offlineDataService: OfflineDataService,
        private timeService: TimeService,
        public scanPageModel: ScanPageModel,
        public settingsPageModel: SettingsPageModel,
        public offlineModePageModel: OfflineModePageModel,
        public menuModel: SideMenuModel,
        public appRouter: AppRouter,
        public installationModel: InstallationPageModel,
        public historyPageModel: HistoryPageModel,
        public appState: AppState,
        heartbeatService: HeartbeatService,
    ) {

        apiService.onUnauthorize = async () => {
            await appRouter.Installation.navigator({}).navigate();
        };

        settingsService.onChange.subscribe(s => {
            apiService.apiRoot = s.apiRoot;
            apiService.apiSecret = s.apiSecret;
            apiService.deviceId = s.deviceId;
            appState.isAnimationEnabled = s.isAnimationEnabled;
        });

        settingsService.onChange.subscribe(s => {
            if (apiService.isInitialized && !s.gateId) {
                appRouter.Settings.navigator({}).navigate();
            }
        });

        settingsService.initialize();
        heartbeatService.start();
        heartbeatService.heartbeat();
        this.offlineDataService.initialize();


        window.addEventListener("error", event => {
            apiService.logError(this.timeService.getTimeIsoString(), event.error);
            return true;
        });
    }

    @computed
    get isInstallationDone() {
        return this.apiService.isInitialized;
    }

    @computed
    get isSettingsDone(): boolean {
        return !!this.settingsService.gateId && !!this.settingsService.checkTypeId;
    }

    @computed get appThemeOptions(): AppThemeOptions {
        return {
            isAnimationEnabled: this.appState.isAnimationEnabled,
            isTestMode: this.settingsService.isTestOnly,
            isLandscapeMode: this.settingsService.isLandscapeEnabled
        };
    }

    defaultRedirect(): Route<any> | null {
        if (this.isSettingsDone) {
            return this.appRouter.Scan;
        }
        if (this.isInstallationDone) {
            return this.appRouter.Settings;
        }
        return this.appRouter.Installation;
    }
}
