import React, { } from "react";
import { autoinject } from "aurelia-dependency-injection";
import { SettingsService } from "../_Services/SettingsService";
import { LayoutContent, Layout, LayoutHeader } from "../_Components/Layout";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Input from "@material-ui/core/Input";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import { CheckType, ErrorState } from "../_Services/DataModel";
import { observer } from "mobx-react";
import { observable, action, computed } from "mobx";
import { AppRouter } from "../AppRouter";
import { r, ResourceKey } from "../_Services/LanguageService";
import { AppModel } from "../AppModel";
import DeleteIcon from "@material-ui/icons/Delete";
import EnterIcon from "@material-ui/icons/AssignmentTurnedIn";
import OfflineGateIcon from "@material-ui/icons/CloudDownload";
import WifiIcon from "@material-ui/icons/Wifi";
import { AppState } from "../AppState";
import { CircularProgress, createMuiTheme, Dialog, DialogContent, MuiThemeProvider } from "@material-ui/core";
import { Theme, ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import { once } from "../_Common/once";
import { confirmAction } from "../_Common/helpers/actionHelper";


@autoinject()
export class SettingsPageModel {
    constructor(
        private settingsService: SettingsService,
        private appRouter: AppRouter,
        private appState: AppState
    ) { }

    @observable checkTypeId: CheckType = CheckType.Check;
    @observable gateId: string = null;
    @observable scanOnScreenTimeout: string = "2000";
    @observable errorOnScreenTimeout: string = "5000";
    @observable scanningLockTimeout: string = "5000";
    @observable isTestOnly: boolean = false;
    @observable isActionTextEnabled: boolean = true;
    @observable useHighResImages: boolean = false;
    @observable isAnimationEnabled: boolean = false;
    @observable isLandscapeEnabled: boolean = false;
    @observable isCameraScanningEnabled: boolean = false;
    @observable requireSuPassword: boolean = true;
    @observable modalIsOpen: boolean = false;
    @observable showSuSettings: boolean = false;
    @observable showLanguageSelector: boolean = true;
    @observable showScanningMode: boolean = true;
    @observable showEventName: boolean = true;
    @observable showGateType: boolean = true;
    @observable isAutomativeMode: boolean = false;
    @observable pass: string = "";

    @observable isLoading: boolean = false;

    @action requestSu() {
        if (this.requireSuPassword) {
            this.modalIsOpen = true;
        } else {
            this.showSuSettings = true;
        }
    }

    @action async suInputValueChanged(value: string) {
        if (await this.appState.checkSuPassword(value)) {
            this.modalIsOpen = false;
            this.showSuSettings = true;
        }
    }

    @computed get gates() {
        return this.settingsService.gates;
    }

    @computed get directions() {
        const gate = this.gates.find(i => i.Id === this.gateId);
        if (!gate) {
            return [];
        }
        return this.settingsService.getAllowedCheckTypes(gate.CheckType);
    }

    @computed get isValid() {
        const isInt = Number.isSafeInteger(Number(this.scanOnScreenTimeout));
        return this.gateId && this.checkTypeId && isInt && parseInt(this.scanOnScreenTimeout, 10) > 0;
    }

    @action setGateId(id: string) {
        this.gateId = id;
        if (this.directions.length > 0) {
            this.checkTypeId = this.directions[0].value;
        }
    }

    @action.bound async apply(): Promise<void> {
        this.isLoading = true;

        if (this.isValid) {
            this.settingsService.checkTypeId = this.checkTypeId;
            this.settingsService.gateId = this.gateId;
            this.settingsService.isTestOnly = this.isTestOnly;
            this.settingsService.isActionTextEnabled = this.isActionTextEnabled;
            this.settingsService.useHighResImages = this.useHighResImages;
            this.settingsService.scanOnScreenTimeout = parseInt(this.scanOnScreenTimeout, 10);
            this.settingsService.errorOnScreenTimeout = parseInt(this.errorOnScreenTimeout, 10);
            this.settingsService.scanningLockTimeout = parseInt(this.scanningLockTimeout, 10);
            this.settingsService.isAnimationEnabled = this.isAnimationEnabled;
            this.settingsService.isLandscapeEnabled = this.isLandscapeEnabled;
            this.settingsService.requireSuPassword = this.requireSuPassword;
            this.settingsService.isCameraScanningEnabled = this.isCameraScanningEnabled;
            this.settingsService.showLanguageSelector = this.showLanguageSelector;
            this.settingsService.showScanningMode = this.showScanningMode;
            this.settingsService.showEventName = this.showEventName;
            this.settingsService.showGateType = this.showGateType;
            this.settingsService.isAutomativeMode = this.isAutomativeMode;

            const isSynced = await this.settingsService.syncSettings();
            if (isSynced) {
                this.appRouter.Scan.navigator({}).navigate();
            }
        }
        this.isLoading = false;
    }

    @action load() {
        this.showSuSettings = false;
        this.pass = "";
        this.setGateId(this.settingsService.gateId);
        if (!!this.settingsService.checkTypeId) {
            this.checkTypeId = this.settingsService.checkTypeId;
        }
        this.isTestOnly = this.settingsService.isTestOnly;
        this.isActionTextEnabled = this.settingsService.isActionTextEnabled;
        this.isCameraScanningEnabled = this.settingsService.isCameraScanningEnabled;
        this.useHighResImages = this.settingsService.useHighResImages;
        this.scanOnScreenTimeout = (this.settingsService.scanOnScreenTimeout || 2000).toString();
        this.errorOnScreenTimeout = (this.settingsService.errorOnScreenTimeout || 5000).toString();
        this.scanningLockTimeout = (this.settingsService.scanningLockTimeout || 5000).toString();
        this.isAnimationEnabled = this.settingsService.isAnimationEnabled || (this.settingsService.isAnimationEnabled !== false && !(window as any).NativeDevice);
        this.isLandscapeEnabled = this.settingsService.isLandscapeEnabled;
        this.showLanguageSelector = this.settingsService.showLanguageSelector;
        this.showScanningMode = this.settingsService.showScanningMode;
        this.showEventName = this.settingsService.showEventName;
        this.showGateType = this.settingsService.showGateType;
        this.requireSuPassword = this.settingsService.requireSuPassword;
        this.isAutomativeMode = this.settingsService.isAutomativeMode;
    }
}

export interface SettingsPageProps {
    model: SettingsPageModel;
    appModel: AppModel;
}

@once((props: SettingsPageProps) => {
    props.model.load();
})
@observer
export class SettingsPage extends React.Component<SettingsPageProps> {
    getSelectMenuStyles(model: SettingsPageModel): React.CSSProperties {
        let menuStyle: React.CSSProperties = {};

        if (model.isLandscapeEnabled) {
            menuStyle = {
                height: "calc(100vw - 75px)",
                maxWidth: "95vh",
                WebkitOverflowScrolling: "touch",
            };
        }

        return menuStyle;
    }

    getCurrentSelectTheme(isLandscapeEnabled: boolean): Theme {
        const themeOptions: ThemeOptions = isLandscapeEnabled ? {
            typography: {
                useNextVariants: true,
            },
            overrides: {
                MuiPopover: {
                    paper: {
                        top: "140px !important",
                        left: "20px !important",
                    }
                },
            },
        } : {
            typography: {
                useNextVariants: true,
            }
        };
        return createMuiTheme(themeOptions);
    }

    render() {
        const { model, appModel } = this.props;

        const items = model.gates
            .map(i => <MenuItem key={i.Id} value={i.Id}>{i.IsOfflineModeSupported && <OfflineGateIcon></OfflineGateIcon>}{!i.IsOfflineModeSupported && <WifiIcon></WifiIcon>}&nbsp;{i.Name}</MenuItem>);

        return <Layout>
            <LayoutHeader title={r("Settings")} goBack={model.apply} isBackDisabled={!model.isValid} />
            <LayoutContent>
                {
                    model.isLoading && <CircularProgress className="container-big" />
                }
                {
                    !model.isLoading && <div className="simple-form form-controls">
                        <FormControl error={!model.gateId} required>
                            <FormLabel htmlFor="gate">{r("Gate")}</FormLabel>
                            <MuiThemeProvider theme={this.getCurrentSelectTheme(model.isLandscapeEnabled)}>
                                <Select
                                    value={model.gateId || ""}
                                    onChange={e => model.setGateId(e.target.value)}
                                    inputProps={{
                                        id: "gate",
                                    }}
                                    MenuProps={{
                                        style: this.getSelectMenuStyles(model),
                                        PaperProps: {
                                            style: {
                                                transform: "translate3d(0, 0, 0)",
                                                overflowX: "auto"
                                            }
                                        }
                                    }}
                                >
                                    {items}
                                </Select>
                            </MuiThemeProvider>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="direction">{r("Direction")}</FormLabel>
                            {
                                !!model.checkTypeId &&
                                <RadioGroup
                                    value={model.checkTypeId.toString()}
                                    onChange={(e: any) => model.checkTypeId = parseInt(e.target.value, 10)}
                                >
                                    {model.directions.map(i =>
                                        <FormControlLabel key={i.value} value={i.value.toString()} control={<Radio />} label={r(i.title as ResourceKey)} />
                                    )}
                                </RadioGroup>
                            }
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="showlanguageselector">{r("Show Language Selector")}</FormLabel>
                            <Switch
                                name="showlanguageselector"
                                checked={model.showLanguageSelector}
                                onChange={e => model.showLanguageSelector = e.target.checked}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="showscanningmode">{r("Show Scanning Mode")}</FormLabel>
                            <Switch
                                name="showscanningmode"
                                checked={model.showScanningMode}
                                onChange={e => model.showScanningMode = e.target.checked}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="showeventname">{r("Show Event Name")}</FormLabel>
                            <Switch
                                name="showeventname"
                                checked={model.showEventName}
                                onChange={e => model.showEventName = e.target.checked}
                            />
                        </FormControl>
                        {
                            !!model.showSuSettings &&
                            <div className="settings-data-container">
                                <FormControl>
                                    <FormLabel htmlFor="iscamerascanningenabled">{r("Enable Camera Scanning")}</FormLabel>
                                    <Switch
                                        name="iscamerascanningenabled"
                                        checked={model.isCameraScanningEnabled}
                                        onChange={e => model.isCameraScanningEnabled = e.target.checked}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="istest">{r("Test mode")}</FormLabel>
                                    <Switch
                                        name="istest"
                                        checked={model.isTestOnly}
                                        onChange={e => model.isTestOnly = e.target.checked}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="showgatetype">{r("Show Gate Type")}</FormLabel>
                                    <Switch
                                        name="showgatetype"
                                        checked={model.showGateType}
                                        onChange={e => model.showGateType = e.target.checked}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="isactiontextenabled">{r("Show Action Text")}</FormLabel>
                                    <Switch
                                        name="isactiontextenabled"
                                        checked={model.isActionTextEnabled}
                                        onChange={e => model.isActionTextEnabled = e.target.checked}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="scantimeout">{r("Screen time (ms)")}</FormLabel>
                                    <Input type="number" inputProps={{ min: 500, step: 100 }}
                                        name="scantimeout"
                                        value={model.scanOnScreenTimeout}
                                        onChange={e => model.scanOnScreenTimeout = e.target.value}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="islandscapeenabled">{r("Portrait/Landscape")}</FormLabel>
                                    <Switch
                                        name="islandscapeenabled"
                                        checked={model.isLandscapeEnabled}
                                        onChange={e => model.isLandscapeEnabled = e.target.checked}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="erroronscreentimeout">{r("Error Screen time (ms)")}</FormLabel>
                                    <Input type="number" inputProps={{ min: 500, step: 100 }}
                                        name="erroronscreentimeout"
                                        value={model.errorOnScreenTimeout}
                                        onChange={e => model.errorOnScreenTimeout = e.target.value}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="scanlocktimeout">{r("Same Badge Scanable Time (ms)")}</FormLabel>
                                    <Input type="number" inputProps={{ min: 500, step: 100 }}
                                        name="scanlocktimeout"
                                        value={model.scanningLockTimeout}
                                        onChange={e => model.scanningLockTimeout = e.target.value}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="usehighresimages">{r("Use High-Res Photos")}</FormLabel>
                                    <Switch
                                        name="usehighresimages"
                                        checked={model.useHighResImages}
                                        onChange={e => model.useHighResImages = e.target.checked}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="isanimationenabled">{r("Enable Animations")}</FormLabel>
                                    <Switch
                                        name="isanimationenabled"
                                        checked={model.isAnimationEnabled}
                                        onChange={e => model.isAnimationEnabled = e.target.checked}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="isautomativemode">{r("Enable Online/Offline Scaning Mode")}</FormLabel>
                                    <Switch
                                        name="isautomativemode"
                                        checked={model.isAutomativeMode}
                                        onChange={e => model.isAutomativeMode = e.target.checked} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="requireSuPassword">{r("Password is required for Advanced Settings")}</FormLabel>
                                    <Switch
                                        name="requireSuPassword"
                                        checked={model.requireSuPassword}
                                        onChange={e => model.requireSuPassword = e.target.checked} />
                                </FormControl>
                                {
                                    appModel.appState.globalError.value !== ErrorState.None &&
                                    <FormControl>
                                        <FormLabel htmlFor="reset App Error">
                                            {r("Reset App Error")}
                                        </FormLabel>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            name="resetAppError"
                                            onClick={_ => confirmAction(() => appModel.appState.globalError.set(ErrorState.None))}
                                        >
                                            {r("Reset App Error")} - {appModel.appState.globalErrorString}
                                        </Button>
                                    </FormControl>
                                }
                            </div>
                        }
                        {
                            !model.showSuSettings && <FormControl >
                                <Button variant="contained" color="primary"
                                    name="modalOpen"
                                    onClick={_ => model.requestSu()}
                                >
                                    {r("Advanced Settings >>")}
                                </Button>
                            </FormControl>
                        }
                        <Dialog
                            className="settings-pin-dialog"
                            open={model.modalIsOpen}
                            onClose={_ => model.modalIsOpen = false}
                            scroll="body"
                        >
                            <DialogContent>
                                <div className="pin-panel">
                                    <h2 >{r("Enter Password")}</h2>
                                    <input type="text" pattern="\d*"
                                        value={model.pass} className="text-security"
                                        onChange={e => {
                                            model.pass = e.target.value;
                                        }}
                                        onKeyDown={e => {
                                            if (e.key === "Enter") {
                                                model.suInputValueChanged(model.pass);
                                            }
                                        }}
                                    />
                                    <div className="number-dial">
                                        {
                                            Array.from(Array(10).keys()).map(i =>
                                                <Button
                                                    key={`btn_${i}`}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={_ => {
                                                        model.pass = (`${model.pass}${i}`);
                                                    }}
                                                >
                                                    {i}
                                                </Button>
                                            )
                                        }
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={_ => model.pass = ""}
                                        >
                                            <DeleteIcon />
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={_ => model.suInputValueChanged(model.pass)}
                                        >
                                            <EnterIcon />
                                        </Button>
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                }
            </LayoutContent >
        </Layout >;
    }
}