import React from "react";
import { observer } from "mobx-react";
import classnames from "classnames";

export interface CountryFlagProps {
    /**
     * country code from https://www.iso.org/obp/ui/; should contains 2 characters
     */
    alpha2IsoCode: string;
    name?: string;
    className?: string;
    bigFlag?: boolean;
    squared?: boolean;
}

@observer
export class CountryFlag extends React.Component<CountryFlagProps, {}> {
    constructor(props: CountryFlagProps) {
        super(props);
    }

    render() {
        const { alpha2IsoCode, className, name, bigFlag, squared } = this.props;
        return (<div className={classnames("st-country-flag", className)}>
            {alpha2IsoCode &&
                <span className={classnames(`flag-icon flag-icon-${alpha2IsoCode.toLowerCase()}`,
                    { flag: bigFlag },
                    { "flag-icon-squared": squared }
                )} />
            }
            {name}
        </div>);
    }
}
