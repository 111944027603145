import { observable } from "mobx";

export class OnlineService {
    constructor() {
        window.addEventListener("online", () => {
            this.isOnline = true;
        });
        window.addEventListener("offline", () => {
            this.isOnline = false;
        });
        this.isOnline = navigator.onLine;
    }

    @observable isOnline: boolean;
}