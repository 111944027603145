import { observable, computed } from "mobx";
import { EnumFlag } from "./_Services/EnumFlag";
import { ErrorState } from "./_Services/DataModel";

export class AppState {
    @observable isMenuOpen: boolean = false;
    @observable isAnimationEnabled: boolean = true;

    globalError: EnumFlag = new EnumFlag(ErrorState.None);
    version: string = (window as any).version || "";

    @computed get globalErrorString(): string {
        return Object.keys(ErrorState).filter(i => this.globalError.hasFlag(ErrorState[i])).toString();
    }

    async checkSuPassword(pass: string): Promise<Boolean> {
        const buffer = await crypto.subtle.digest("SHA-512", new TextEncoder().encode(pass));
        return Array.prototype.map.call(new Uint8Array(buffer), i => (("00" + i.toString(16)).slice(-2))).join("") === "1e29a8a7b425ad0e220b1f8dd5a0383ae733716530ee07ad603c5b23ab0f1c16ed82a645749471d7bd99f3cdc37cccb1dee6896cfc9b56ef59aa5c09df1898ff";
    }
}