import { action, observable, autorun, IReactionDisposer, computed } from "mobx";
import { DataStorage } from "./DataStorage";
import { CheckType, IGateDto, GateDtoCheckType, GateMode } from "./DataModel";
import { autoinject } from "aurelia-dependency-injection";
import { Subject, IObservable } from "@st/globster-model/lib/src/Subject";
import { uuid } from "@st/globster-data";
import { r } from "./LanguageService";
import { ApiService } from "./ApiService";
import { TimeService } from "./TimeService";

const settingsKey = "appSettings";

export interface SettingsObject {
    checkTypeId: CheckType;
    configurationVersion: string;
    apiRoot: string;
    apiSecret: string;
    deviceId: string;
    deviceHardwareId: string;
    deviceName: string;

    homeTitles?: string[];
    homeImages?: string[];
    gates?: IGateDto[];

    isTestOnly?: boolean;
    isActionTextEnabled: boolean;
    useHighResImages?: boolean;
    pullNewBadgesOnSync?: boolean;
    gateId?: string;
    scanOnScreenTimeout: number;
    errorOnScreenTimeout: number;
    scanningLockTimeout: number;
    isAnimationEnabled: boolean;
    isLandscapeEnabled: boolean;
    isCameraScanningEnabled: boolean;

    showLanguageSelector: boolean;
    showScanningMode: boolean;
    showEventName: boolean;
    showGateType: boolean;
    isAutomativeMode: boolean;

    requireSuPassword: boolean;
    automaticPhotoDownload: boolean;

}

@autoinject()
export class SettingsService implements SettingsObject {
    constructor(private apiService: ApiService, private storage: DataStorage, private timeService: TimeService) {
        this.onChange = this._onChange.asObservable();
    }

    private initializationToken: IReactionDisposer;
    private _onChange = new Subject<SettingsObject>();
    private updateInProgress: any;

    @observable checkTypeId: CheckType;
    @observable configurationVersion: string;
    @observable apiRoot: string;
    @observable apiSecret: string;
    @observable deviceId: string;
    @observable deviceHardwareId: string;
    @observable deviceName: string;
    @observable languageId: string;
    @observable homeTitles: string[] = [];
    @observable homeImages: string[] = [];
    @observable gates: IGateDto[] = [];
    @observable gateId: string;
    @observable scanOnScreenTimeout: number;
    @observable errorOnScreenTimeout: number;
    @observable scanningLockTimeout: number;
    @observable isTestOnly: boolean = false;
    @observable isActionTextEnabled: boolean = true;
    @observable useHighResImages: boolean = false;
    @observable pullNewBadgesOnSync: boolean = true;
    @observable requireSuPassword: boolean = true;
    @observable automaticPhotoDownload: boolean = true;
    @observable isAnimationEnabled: boolean = false;
    @observable isLandscapeEnabled: boolean = false;
    @observable isCameraScanningEnabled: boolean = false;
    @observable showLanguageSelector: boolean = true;
    @observable showScanningMode: boolean = true;
    @observable showEventName: boolean = true;
    @observable showGateType: boolean = true;
    @observable isAutomativeMode: boolean = false;

    heartbeatInterval: number = 10000;
    offlineSyncInterval: number = 30000;
    serverTimeOffsetLock: number = 600000; // 10min

    onChange: IObservable<SettingsObject>;

    @computed get gate(): IGateDto {
        return this.gates.find(i => i.Id === this.gateId);
    }

    @computed get checkType() {
        return this.checkTypes.find(i => i.value === this.checkTypeId);
    }
    @computed get gateMode(): GateMode {
        if (this.gate && this.gate.IsOfflineModeSupported && this.isAutomativeMode) {
            if (this.apiService.canSend) {
                return GateMode.Online;
            }
            return GateMode.Offline;
        }
        return GateMode.Online;
    }

    get checkTypes() {
        return [
            { value: CheckType.Check, title: r("Check") },
            { value: CheckType.In, title: r("In") },
            { value: CheckType.Out, title: r("Out") },
            { value: CheckType.Auto, title: r("Auto") },
        ];
    }

    getAllowedCheckTypes(gateCheckType: GateDtoCheckType | null = null) {
        gateCheckType = gateCheckType || (this.gate && this.gate.CheckType);
        const result = [];
        if ((gateCheckType & GateDtoCheckType.Check) > 0) {
            result.push({ value: CheckType.Check, title: "Check" });
        }
        if ((gateCheckType & GateDtoCheckType.In) > 0) {
            result.push({ value: CheckType.In, title: "In" });
        }
        if ((gateCheckType & GateDtoCheckType.Out) > 0) {
            result.push({ value: CheckType.Out, title: "Out" });
        }
        if ((gateCheckType & GateDtoCheckType.Auto) > 0) {
            result.push({ value: CheckType.Auto, title: "Auto" });
        }

        return result;
    }

    @action
    initialize() {
        this.load();
        this.initializationToken = autorun(() => {
            this.apply();
        });
    }

    @action
    dispose() {
        this.initializationToken();
    }

    @action
    reset() {
        this.apiRoot = null;
        this.apiSecret = null;
        this.deviceId = null;
        this.homeTitles = [];
        this.homeImages = [];
        this.gates = [];
        this.gateId = null;
        this.isTestOnly = false;
        this.useHighResImages = false;
        this.store();
    }

    @action update(action: (s: SettingsObject) => void) {
        this.updateInProgress = true;
        action(this);
        this.updateInProgress = false;
        this.apply();
    }

    private apply() {
        if (this.updateInProgress) {
            return;
        }

        const settings = this.store();
        this._onChange.emit(settings);
    }

    private store() {
        const settings: SettingsObject = {
            checkTypeId: this.checkTypeId,
            configurationVersion: this.configurationVersion,
            apiRoot: this.apiRoot,
            apiSecret: this.apiSecret,
            deviceId: this.deviceId,
            deviceHardwareId: this.deviceHardwareId,
            deviceName: this.deviceName,
            gateId: this.gateId,
            gates: this.gates,
            homeImages: this.homeImages,
            homeTitles: this.homeTitles,
            isTestOnly: this.isTestOnly,
            isActionTextEnabled: this.isActionTextEnabled,
            useHighResImages: this.useHighResImages,
            scanOnScreenTimeout: this.scanOnScreenTimeout,
            errorOnScreenTimeout: this.errorOnScreenTimeout,
            scanningLockTimeout: this.scanningLockTimeout,
            isAnimationEnabled: this.isAnimationEnabled,
            isLandscapeEnabled: this.isLandscapeEnabled,
            pullNewBadgesOnSync: this.pullNewBadgesOnSync,
            automaticPhotoDownload: this.automaticPhotoDownload,
            isCameraScanningEnabled: this.isCameraScanningEnabled,
            showLanguageSelector: this.showLanguageSelector,
            showScanningMode: this.showScanningMode,
            showEventName: this.showEventName,
            showGateType: this.showGateType,
            isAutomativeMode: this.isAutomativeMode,
            requireSuPassword: this.requireSuPassword
        };

        this.storage.setObject(settingsKey, settings);

        return settings;
    }

    private load() {
        const settings = this.storage.getObject<SettingsObject>(settingsKey);
        if (settings) {
            this.apiRoot = settings.apiRoot;
            this.apiSecret = settings.apiSecret;
            this.deviceId = settings.deviceId;
            this.deviceHardwareId = settings.deviceHardwareId || uuid();
            this.deviceName = settings.deviceName;
            this.checkTypeId = settings.checkTypeId;
            this.configurationVersion = settings.configurationVersion;
            this.gateId = settings.gateId;
            this.gates = settings.gates;
            this.homeImages = settings.homeImages;
            this.homeTitles = settings.homeTitles;
            this.isTestOnly = settings.isTestOnly;
            this.isActionTextEnabled = settings.isActionTextEnabled;
            this.useHighResImages = settings.useHighResImages;
            this.scanOnScreenTimeout = settings.scanOnScreenTimeout;
            this.errorOnScreenTimeout = settings.errorOnScreenTimeout;
            this.scanningLockTimeout = settings.scanningLockTimeout;
            this.isAnimationEnabled = settings.isAnimationEnabled;
            this.isLandscapeEnabled = settings.isLandscapeEnabled;
            this.isCameraScanningEnabled = settings.isCameraScanningEnabled;
            this.pullNewBadgesOnSync = settings.pullNewBadgesOnSync;
            this.automaticPhotoDownload = settings.automaticPhotoDownload;
            this.showLanguageSelector = settings.showLanguageSelector;
            this.showScanningMode = settings.showScanningMode;
            this.showEventName = settings.showEventName;
            this.showGateType = settings.showGateType;
            this.isAutomativeMode = settings.isAutomativeMode;
            this.requireSuPassword = settings.requireSuPassword;
        } else {
            this.deviceHardwareId = uuid();
        }
    }

    async syncSettings(): Promise<boolean> {
        if (this.gateMode === GateMode.Online) {
            try {
                const response = await this.apiService.updateStatus({
                    Time: this.timeService.getTimeIsoString(),
                    CheckType: this.checkTypeId,
                    GateId: this.gateId,
                });

                if (!response) return false;
            } catch {
                console.error("Synchronization of settings failed.");
            }
        }
        return true;
    }

    public get checkIsInOut(): boolean {
        return this.gate && this.gate.CheckType === GateDtoCheckType.InOut;
    }

    public get checkIsAutoInOut(): boolean {
        return this.gate && this.gate.CheckType === GateDtoCheckType.AutoInOut;
    }

    public get checkIsSeparatedOne(): boolean {
        return this.gate && this.gate.CheckType !== GateDtoCheckType.AutoInOut && this.gate.CheckType !== GateDtoCheckType.InOut;
    }
}