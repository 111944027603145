import { IObservable, Subject } from "@st/globster-model/lib/src/Subject";

export class NativeAppService {

    constructor() {
        this.nfcScanned = this.nfcScannedSubject.asObservable();
    }

    private nfcScannedSubject = new Subject<string>();
    nfcScanned: IObservable<string>;

    initialize() {
        const that = this;
        (window as any)._nativeProxy = {
            nfcScanned() {
                const code = (window as any).NativeDevice.getNfcId();
                that.nfcScannedSubject.emit(code);
            }
        };
    }
}