import "./polyfills";
import * as React from "react";
import { render } from "react-dom";
import { App } from "./App";
import { Container } from "aurelia-dependency-injection";
import { AppModel } from "./AppModel";
import { AppRouter } from "./AppRouter";
import { RouterModel, startRouter } from "@st/globster-router";
import { createHashHistory } from "history";
import { NativeAppService } from "./_Services/NativeAppService";
import { AppState } from "./AppState";

console.log(`[App] initializing... broswer: ${navigator.userAgent}`);

function registerServices(): Container {
    const container = new Container();
    container.registerInstance(RouterModel, container.get(AppRouter));
    container.registerSingleton(AppState);
    container.makeGlobal();
    return container;
}

const container = registerServices();
const model = container.get(AppModel) as AppModel;
const router = container.get(AppRouter) as AppRouter;

const nativeAppService = container.get(NativeAppService) as NativeAppService;
nativeAppService.initialize();

const basePath = (window as any).appBasePath || "";
const history = createHashHistory({
    basename: basePath,
});

router.Root.redirect = () => model.defaultRedirect();
startRouter(router, history);
router.setHomeUrl("/app");
router.navigateHome();

render(<App model={model} />, document.getElementById("app"));


if (navigator.onLine) {
    if ("serviceWorker" in navigator) {
        console.log("Registering service worker...");

        navigator.serviceWorker
            .register("/service-worker.js")
            .then((reg) => {
                reg.addEventListener("updatefound", function () {
                    const installingWorker = reg.installing;
                    console.log("A new service worker is being installed:", installingWorker);
                });
            }).catch((err) => {
                console.info("Service Worker registration failed with:");
                console.error(err);
            });
    } else {
        console.info("Service workers not supported. NO OFFLINE MODE SUPPORTED");
    }
} else {
    console.info("Offline - no service worker update.");
}

console.log("[App] initialization finished");