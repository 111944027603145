import React from "react";
import { observer } from "mobx-react";

export interface TextFieldProps {
    title: string;
    getValue: () => string;
    setValue: (value: string) => void;
    type?: string;
}

@observer
export class TextField extends React.Component<TextFieldProps> {
    render() {
        const { getValue, setValue, title, type = "text" } = this.props;
        const value = getValue();
        return <div className="mdl-textfield mdl-js-textfield">
        <input
            className="mdl-textfield__input"
            type={type}
            value={value}
            onChange={e => setValue(e.target.value)}
        />
        {!value && (
            <label className="mdl-textfield__label" htmlFor="sample1">
                {title}
            </label>
        )}
    </div>;
    }
}