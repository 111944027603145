import React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconSettings from "@material-ui/icons/Settings";
import OfflineGateIcon from "@material-ui/icons/CloudDownload";
import IconPhonelinkSetup from "@material-ui/icons/PhonelinkSetup";
import IconList from "@material-ui/icons/List";
import Divider from "@material-ui/core/Divider";
import { observer } from "mobx-react";
import { observable, action, computed } from "mobx";
import { AppRouter } from "../AppRouter";
import { autoinject } from "aurelia-dependency-injection";
import { r } from "../_Services/LanguageService";
import { FormControl, FormLabel } from "@material-ui/core";
import { SettingsService } from "../_Services/SettingsService";
import { AppState } from "../AppState";

@autoinject()
export class SideMenuModel {
    constructor(public appRouter: AppRouter,
        private settingsService: SettingsService,
        public appState: AppState) { }

    @observable isMenuOpen: boolean = false;

    @action.bound navigateToSettings() {
        this.appRouter.Settings.navigator({}).navigate();
        this.isMenuOpen = false;
    }

    @action.bound navigateToOfflineMode() {
        this.appRouter.OfflineMode.navigator({}).navigate();
        this.isMenuOpen = false;
    }

    @action.bound navigateToInstallation() {
        this.appRouter.Installation.navigator({}).navigate();
        this.isMenuOpen = false;
    }

    @action.bound navigateToHistory() {
        this.appRouter.History.navigator({}).navigate();
        this.isMenuOpen = false;
    }

    @computed get deviceName(): string {
        return this.settingsService.deviceName;
    }
}

export interface SideMenuProps {
    model: SideMenuModel;
}

@observer
export class SideMenu extends React.Component<SideMenuProps> {

    render() {
        const { model } = this.props;
        return <SwipeableDrawer
            open={model.isMenuOpen}
            onOpen={() => model.isMenuOpen = true}
            onClose={() => model.isMenuOpen = false}
            style={{ zIndex: 3000 }}
        >
            <div className="sidemenu-header">
                <div>{r("User Menu")}</div>
                <FormControl className="sidemenu-device-name">
                    <FormLabel>
                        {r("Device Name")}
                    </FormLabel>
                    <div>{model.deviceName}</div>
                </FormControl>
            </div>
            <Divider />
            <List>
                <ListItem button onClick={model.navigateToSettings}>
                    <ListItemIcon>
                        <IconSettings />
                    </ListItemIcon>
                    <ListItemText primary={r("Settings")} />
                </ListItem>
                <ListItem button onClick={model.navigateToOfflineMode}>
                    <ListItemIcon>
                        <OfflineGateIcon />
                    </ListItemIcon>
                    <ListItemText primary={r("Offline Mode")} />
                </ListItem>
                <ListItem button onClick={model.navigateToHistory}>
                    <ListItemIcon>
                        <IconList />
                    </ListItemIcon>
                    <ListItemText primary={r("Scanning History")} />
                </ListItem>
                <Divider />
                <ListItem button onClick={model.navigateToInstallation}>
                    <ListItemIcon>
                        <IconPhonelinkSetup />
                    </ListItemIcon>
                    <ListItemText primary={r("Installation")} />
                </ListItem>
            </List>

            <div className="version sidemenu-version">{model.appState.version}</div>
        </SwipeableDrawer>;
    }
}