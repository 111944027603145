import React from "react";
import { getCurrentLanguage, getLanguage, languages, r, setCurrentLanguage } from "../_Services/LanguageService";
import { CountryFlag } from "./Flag";
import { Dropdown } from "react-bootstrap";

export class LanguageSelector extends React.Component {
    render() {
        return (
            <Dropdown className={"language-selector"}>
                <Dropdown.Toggle
                    variant="outline-secondary"
                    size="sm"
                    className="st-language-name"
                    id="languageSelectorButton"
                >
                    <span className={"language-label"}>{r("Choose your language:")}</span>
                    <CountryFlag alpha2IsoCode={getCurrentLanguage().ico} />
                    <span className="language-title">{getCurrentLanguage().title}</span>
                    <span><i className="fa fa-caret-down" aria-hidden="true"></i></span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    className="dropdown-menu-right"
                    aria-labelledby="languageSelectorButton"
                    align="right"
                >
                    {
                        languages.map(lang => (
                            <Dropdown.Item key={`lang-selector-item-${lang.id}`}
                                className="dropdown-item" onClick={(e) => {
                                    e.preventDefault();
                                    setCurrentLanguage(lang.id);
                                    this.forceUpdate();
                                }}>
                                <CountryFlag alpha2IsoCode={getLanguage(lang.id).ico} />
                                <span className="language-title">{lang.title}</span>
                            </Dropdown.Item>
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}