import { observable } from "mobx";


const resources = observable({
    languageId: "en",
    map: null
});

export type ResourceKey = "In"
    | "Out"
    | "Auto"
    | "Check"
    | "Apply"
    | "Close"
    | "Advanced Settings >>"
    | "Cancel"
    | "Settings"
    | "Installation"
    | "Scanning History"
    | "Gate"
    | "Direction"
    | "Language"
    | "Screen time (ms)"
    | "Screen expiration time (ms)"
    | "Error Screen time (ms)"
    | "Test mode"
    | "Enable Animations"
    | "Use High-Res Photos"
    | "Clear"
    | "Password is required for Advanced Settings"
    | "Auto-Pull Badges"
    | "Auto-Pull Photos"
    | "Update Failed"
    | "Number of downloaded Badges:"
    | "Device Name"
    | "Gate Checks"
    | "Enter Password"
    | "Are you sure?"
    | "to delete"
    | "Used Storage:"
    | "Delete Offline Data"
    | "Delete Offline Photos"
    | "Delete Scanning History (only synced)"
    | "Delete Scanning History (including non-synced)"
    | "Reset App Error"
    | "Download Offline Badge Data"
    | "Force-Download Offline Badge Data"
    | "Download Offline Photos"
    | "Register Device"
    | "Installation Token"
    | "URL for server API"
    | "Device Registration"
    | "URL should point to API and include Installation Token"
    | "Offline Mode"
    | "Last Sync Data (version)"
    | "Photos"
    | "to be uploaded"
    | "Enable Camera Scanning"
    | "Already Checked"
    | "Badge has been deactivated"
    | "Badge does not exist"
    | "Badge is not collected"
    | "Number of Passes Exceeded"
    | "Incorrect Category"
    | "Invalid time range"
    | "Missing Badge Entitlement"
    | "Missing Any Badge Validity"
    | "Missing Venue"
    | "Missing Zone"
    | "Badge is not valid for current date"
    | "Choose your language:"
    | "Portrait/Landscape"
    | "Show Action Text"
    | "Send person to accreditation center"
    | "Collect badge and send person to accreditation center"
    | "Check badge design and security sticker carefully. If ok, send person to accreditation center. Otherwise collect badge"
    | "Use different entrance"
    | "Explain that badge is not valid today / Send person to accreditation center"
    | "Ensure the person is the one indicated on the badge (photo) - if ok scan out and in again"
    | "Show Language Selector"
    | "Show Scanning Mode"
    | "Show Event Name"
    | "Show Gate Type"
    ;

const czechLanguage: { [key in ResourceKey]: string } = {
    "In": "Dovnitř",
    "Out": "Ven",
    "Auto": "Auto",
    "Check": "Kontrola",
    "Close": "Zavřít",
    "Apply": "Uložit",
    "Advanced Settings >>": "Pokročilé Nastavení >>",
    "Cancel": "Storno",
    "Settings": "Nastavení",
    "Installation": "Instalace",
    "Scanning History": "Historie Kontrol Akreditací",
    "Gate": "Brána",
    "Direction": "Směr",
    "Language": "Jazyk",
    "Screen time (ms)": "Jak dlouho zobrazit kontrolní dialog (ms)",
    "Screen expiration time (ms)": "Screen expiration time (ms)",
    "Error Screen time (ms)": "Jak dlouho zobrazit chybu (ms)",
    "Test mode": "Testovací mód",
    "Enable Animations": "Použít animace",
    "Enable Camera Scanning": "Použít kameru ke scannování",
    "Use High-Res Photos": "Použít obrázky s vysokým rozlišením",
    "Clear": "Smazat vše",
    "Password is required for Advanced Settings": "Heslo je vyžadováno pro Pokročilé Nastavení",
    "Auto-Pull Badges": "Automatické Stažení Akreditací",
    "Auto-Pull Photos": "Automatické Stažení Fotek",
    "Update Failed": "Aktualizace selhala",
    "Number of downloaded Badges:": "Počet stažených akreditací:",
    "Device Name": "Název Zařízení",
    "Gate Checks": "Počet kontrol akreditací",
    "Enter Password": "Vložte heslo",
    "Are you sure?": "Jste si jistý?",
    "to delete": "ke smazání",
    "Used Storage:": "Použitá velikost disku:",
    "Delete Offline Data": "Smaž Offline Data",
    "Delete Offline Photos": "Smaž Fotky pro offline mode",
    "Delete Scanning History (only synced)": "Smazat Historii Kontrol Akreditací (pouze synchronizované)",
    "Delete Scanning History (including non-synced)": "Smazat Historii Kontrol Akreditací (včetně nesynchronizovaných)",
    "Reset App Error": "Chyba aplikace",
    "Download Offline Photos": "Stáhni fotky pro offline mode",
    "Download Offline Badge Data": "Stáhni data pro offline mode",
    "Force-Download Offline Badge Data": "Stáhni a přepiš data pro offline mode",
    "Register Device": "Zaregistruj zařízení",
    "Installation Token": "Instalační kód",
    "URL for server API": "URL pro server API",
    "Device Registration": "Registrace zařízení",
    "URL should point to API and include Installation Token": "URL musí odkazovat na API a musí obsahovat instalační kód",
    "Offline Mode": "Offline Mód",
    "Last Sync Data (version)": "Poslední synchronizace dat (verze)",
    "Photos": "Fotky",
    "to be uploaded": "nesynchronizovaných",
    "Already Checked": "Akreditace již byla kontrolovaná",
    "Badge has been deactivated": "Akreditace byla zrušená",
    "Badge does not exist": "Akreditace neexistuje",
    "Number of Passes Exceeded": "Nedovolený počet vstupů",
    "Incorrect Category": "Nevalidní kategorie",
    "Invalid time range": "",
    "Missing Badge Entitlement": "Chybějící akreditační právo",
    "Missing Any Badge Validity": "Chybějící akreditační právo",
    "Missing Venue": "Chybějící město",
    "Missing Zone": "Chybějící zóna",
    "Badge is not valid for current date": "Akreditace není validní pro dnešní den",
    "Badge is not collected": "Akreditace nebyla aktivovaná",
    "Choose your language:": "Vyberte si jazyk:",
    "Portrait/Landscape": "Orientace Vertikální/Horizotální",
    "Show Action Text": "Zobraz Text Akce",
    "Send person to accreditation center": "Pošli osobu do akreditačního centra",
    "Collect badge and send person to accreditation center": "Odeber akreditaci a pošli osobu do akreditačního centra",
    "Check badge design and security sticker carefully. If ok, send person to accreditation center. Otherwise collect badge": "Zkontroluj akreditaci. Pokud je v pořádku, pošli osobu do akreditačního centra. Jinak odeber akreditaci.",
    "Use different entrance": "Použij jiný vstup",
    "Explain that badge is not valid today / Send person to accreditation center": "Vysvětli, že akreditace není dneska validní/Pošli osobu do akreditačního centra",
    "Ensure the person is the one indicated on the badge (photo) - if ok scan out and in again": "Zkontroluj majitele akreditace podle fotky - pokud je v pořádku, naskenuj akreditaci ještě jednou",
    "Show Language Selector": "Zobraz přepínač jazyků",
    "Show Scanning Mode": "Zobraz typ módu",
    "Show Event Name": "Zobraz jméno akce",
    "Show Gate Type": "Zobraz typ vstupu",
};

const germanLanguage: { [key in ResourceKey]: string } = {
    "In": "In",
    "Out": "Out",
    "Auto": "Auto",
    "Check": "Kontrolle",
    "Apply": "Anwenden",
    "Close": "Scliessen",
    "Advanced Settings >>": "Erweiterte Einstellungen >>",
    "Cancel": "Abbrechen",
    "Settings": "Einstellungen",
    "Installation": "Installation",
    "Scanning History": "Verlauf Kontrollen",
    "Gate": "Eingang",
    "Direction": "Richtung",
    "Language": "Sprache",
    "Screen time (ms)": "Anzeigedauer Kontrolle (ms)",
    "Screen expiration time (ms)": "Screen expiration time (ms)",
    "Error Screen time (ms)": "Zeit für Fehlermeldungen (ms)",
    "Test mode": "Test-Modus",
    "Enable Animations": "Animationen Ein",
    "Use High-Res Photos": "Fotos in hoher Auflösung",
    "Clear": "Löschen",
    "Password is required for Advanced Settings": "Zum Ändern der erweiterten Einstellungen wird ein Passwort benötigt",
    "Auto-Pull Badges": "Download Akkreditierungen",
    "Auto-Pull Photos": "Download Fotos",
    "Update Failed": "Update fehlgeschlagen",
    "Number of downloaded Badges:": "Anzahl heruntergeladener Akkreditierungen:",
    "Device Name": "Gerätename",
    "Gate Checks": "Eingangskontrollen",
    "Enter Password": "Passwort eingeben",
    "Are you sure?": "Sind sie sicher?",
    "to delete": "muss noch gelöscht werden",
    "Used Storage:": "Speichernutzungx:",
    "Delete Offline Data": "Lösche Offline Daten",
    "Delete Offline Photos": "Lösche Offline Fotos",
    "Delete Scanning History (only synced)": "Lösche Scan-Historie (nur synchronisierte)",
    "Delete Scanning History (including non-synced)": "Lösche Scan-Historie (inkl. noch nicht synchronisierte)",
    "Reset App Error": "App-Fehler zurücksetzen",
    "Download Offline Badge Data": "Download Offline Daten",
    "Force-Download Offline Badge Data": "Erzwinge Download von Offline-Daten",
    "Download Offline Photos": "Download Offline Fotos",
    "Register Device": "Regisriere das Gerät",
    "Installation Token": "Installations-Code",
    "URL for server API": "URL für Server-API",
    "Device Registration": "Geräte-Registirerung",
    "URL should point to API and include Installation Token": "Die URL sollte auf die API verweisen und das Installationstoken enthalten",
    "Offline Mode": "Offline Modus",
    "Last Sync Data (version)": "Letzte Synchronisation (Version)",
    "Photos": "Fotos",
    "to be uploaded": "muss noch hochgeladen werden",
    "Enable Camera Scanning": "Kamera-Scan Ein",
    "Already Checked": "Bereits benutzt",
    "Badge has been deactivated": "Akkreditierung wurde gesperrt",
    "Badge does not exist": "Akkreditierung existiert nicht",
    "Badge is not collected": "Akkreditierung ist noch nicht aktiviert",
    "Number of Passes Exceeded": "Akkreditierung zu oft benutzt",
    "Incorrect Category": "Falsche Kategorie",
    "Invalid time range": "",
    "Missing Badge Entitlement": "Akkreditierung an diesem Eingang ungültig",
    "Missing Any Badge Validity": "Akkreditierung nicht gültig (unbekannter Grund)",
    "Missing Venue": "Akkreditierung an diesem Austragungsort nicht gültig",
    "Missing Zone": "Akkreditierung an diesem Eingang ungültig",
    "Badge is not valid for current date": "Akkreditierung ist für den heutigen Tag nicht gültig",
    "Choose your language:": "Wählen sie die Sprache:",
    "Portrait/Landscape": "Vertikal/Horizontal",
    "Show Action Text": "Zeige empfohlene Aktion",
    "Send person to accreditation center": "Person zum Akkreditierungszentrum schicken",
    "Collect badge and send person to accreditation center": "Sammeln Sie den Ausweis und schicken Sie die Person zum Akkreditierungszentrum",
    "Check badge design and security sticker carefully. If ok, send person to accreditation center. Otherwise collect badge": "Überprüfen Sie das Ausweisdesign und den Sicherheitsaufkleber sorgfältig. Wenn ok, schicken Sie die Person zum Akkreditierungszentrum. Ansonsten Abzeichen sammeln",
    "Use different entrance": "Anderen Eingang verwenden",
    "Explain that badge is not valid today / Send person to accreditation center": "Erklären Sie, dass der Ausweis heute nicht gültig ist / Schicken Sie die Person zum Akkreditierungszentrum",
    "Ensure the person is the one indicated on the badge (photo) - if ok scan out and in again": "Stellen Sie sicher, dass es sich um die Person handelt, die auf dem Ausweis (Foto) angegeben ist - wenn in Ordnung, scannen Sie sie aus und wieder ein",
    "Show Language Selector": "Sprachauswahl anzeigen",
    "Show Scanning Mode": "Scanmodus anzeigen",
    "Show Event Name": "Ereignisnamen anzeigen",
    "Show Gate Type": "Tortyp anzeigen",
};

export const languages = [
    { id: "en", title: "English", map: null, ico: "gb" },
    { id: "cz", title: "Česky", map: czechLanguage, ico: "cz" },
    { id: "de", title: "Deutsch", map: germanLanguage, ico: "de" },
];

export function getLanguage(languageId: string) {
    return languages.find(i => i.id === languageId);
}

export function getCurrentLanguage() {
    return languages.find(i => i.id === resources.languageId);
}

export function setCurrentLanguage(id: string) {
    resources.languageId = id || "en";
    resources.map = getCurrentLanguage().map;
}

export function r(key: ResourceKey | string): string {
    if (resources.map === null) {
        return key;
    }
    return resources.map[key] || key;
}
