import { RouterModel } from "@st/globster-router";

export class AppRouter extends RouterModel {
    constructor() {
        super();
    }

    Root = this.rootRoute({
        path: "/app",
    });

    Installation = this.route(this.Root, {
        path: "installation",
        title: "Installation"
    });

    Settings = this.route(this.Root, {
        path: "settings",
        title: "Settings",
    });

    OfflineMode = this.route(this.Root, {
        path: "offlineMode",
        title: "Offline Mode",
    });

    Scan = this.route(this.Root, {
        path: "scan",
        title: "Scanning"
    });

    History = this.route(this.Root, {
        path: "history",
        title: "History"
    });

}