import * as React from "react";
import { observer } from "mobx-react";
import { AppModel, AppThemeOptions } from "./AppModel";
import { InstallationPage } from "./Pages/InstallationPage";
import { ScanPage } from "./Pages/ScanPage";
import { SettingsPage } from "./Pages/SettingsPage";
import { OfflineModePage } from "./Pages/OfflineModePage";
import { CurrentRoute } from "@st/globster-router";
import { HistoryPage } from "./Pages/HistoryPage";
import { createMuiTheme, CssBaseline, MuiThemeProvider, Theme } from "@material-ui/core";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

@observer
export class App extends React.Component<{ model: AppModel }> {
    createAppTheme(options: AppThemeOptions): Theme {
        let themeOptions: ThemeOptions = null;

        if (options.isAnimationEnabled) {
            themeOptions = {
                typography: {
                    useNextVariants: true,
                },
            };
        } else {
            themeOptions = {
                typography: {
                    useNextVariants: true,
                },
                transitions: {
                    create: () => "none",
                },
                props: {
                    MuiButtonBase: {
                        disableRipple: true,
                        disableTouchRipple: true,
                    },
                },
            };
        }

        if (options.isTestMode) {
            themeOptions.palette = {
                background: {
                    paper: "#ffbbbb",
                    default: "#ffbbbb"
                },
            };
        } else {
            themeOptions.palette = {
                background: {
                    paper: "#ffffff",
                    default: "#ffffff"
                }
            };
        }

        if (options.isLandscapeMode) {
            document.body.className = "landscape-transform";
        } else {
            document.body.className = "";
        }

        return createMuiTheme(themeOptions);
    }

    render() {
        const { model } = this.props;
        const { appRouter } = model;
        const theme = this.createAppTheme(model.appThemeOptions);

        return (
            <div className="app-container">
                <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <CurrentRoute route={appRouter.Installation}>
                        <InstallationPage model={model.installationModel} />
                    </CurrentRoute>
                    <CurrentRoute route={appRouter.Settings}>
                        <SettingsPage model={model.settingsPageModel} appModel={model} />
                    </CurrentRoute>
                    <CurrentRoute route={appRouter.OfflineMode}>
                        <OfflineModePage model={model.offlineModePageModel} appModel={model} />
                    </CurrentRoute>
                    <CurrentRoute route={appRouter.Scan}>
                        <ScanPage model={model.scanPageModel} menuModel={model.menuModel} appModel={model} />
                    </CurrentRoute>
                    <CurrentRoute route={appRouter.History}>
                        <HistoryPage model={model.historyPageModel} />
                    </CurrentRoute>
                    <CurrentRoute route={appRouter.Root}>ROOT ROUTE</CurrentRoute>
                </MuiThemeProvider>
            </div>
        );
    }
}
