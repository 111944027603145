import { autoinject } from "aurelia-dependency-injection";
import { ApiService } from "./ApiService";
import { AppDb, CheckData } from "./AppDb";
import { action, observable } from "mobx";
import { ICheckRequest, ICheckResponse, IOfflineGateConfiguration, ICheckOfflineRequest, DeclineReason, BadgeState, IOfflineBadge, IDeclineMessage } from "./DataModel";
import { SettingsService } from "./SettingsService";


@autoinject()
export class OfflineDataService {
    constructor(
        private apiService: ApiService,
        private appDb: AppDb,
        private settingsService: SettingsService) {
    }

    private intervalHandle: number = null;

    @observable photosTotalRequired: number = 0;
    @observable photosTotalStored: number = 0;
    @observable totalBadges: number = 0;
    @observable photosDownloadInProgess: boolean = false;
    @observable dataVersion: string;
    @observable dataUpdateTime: string;



    @action async initialize() {
        this.updateOfflineData(await this.appDb.getOfflineConfigurationWithoutBadges(), false);
        this.start();
    }

    @action start() {
        this.stop();

        this.syncer();
        this.intervalHandle = setInterval(() => this.syncer(), this.settingsService.offlineSyncInterval);
    }
    @action private async syncer() {
        if (this.apiService.canSend) {
            const pending = await this.appDb.getPendingItems();
            const pendingByGate: { [id: string]: CheckData[]; } = pending.reduce((r, a) => {
                r[a.GateId] = r[a.GateId] || [];
                r[a.GateId].push(a);
                return r;
            }, {});
            for (const gateId in pendingByGate) {
                const apiObj: ICheckOfflineRequest[] = pendingByGate[gateId].map<ICheckOfflineRequest>(i => {
                    return {
                        TickId: i.TickId,
                        BadgeId: i.BadgeId,
                        CheckType: i.CheckType,
                        DeviceId: this.settingsService.deviceId,
                        GateId: i.GateId,
                        GeneratedDeclineReason: i.GeneratedDeclineReason,
                        IsValid: i.IsValid,
                        DateTime: i.Timestamp,
                        InvalidMessage: i.Message
                    };
                });

                try {
                    const responseOk = await this.apiService.checkSync(apiObj);
                    if (!responseOk) {
                        throw "Negative response from server";
                    }
                    this.appDb.markSynchronized(pending);
                    console.log(`check synced ${pending.map(i => i.TickId)}`);
                } catch (e) {
                    console.info("check sync failed:");
                    console.error(e);
                }
            }

            if (this.dataVersion && this.settingsService.pullNewBadgesOnSync) {
                await this.downloadOfflineBadges();
            }
        }
    }

    @action stop() {
        if (this.intervalHandle !== null) {
            clearInterval(this.intervalHandle);
            this.intervalHandle = null;
        }
    }



    @action private async updateOfflineData(offlineData: IOfflineGateConfiguration, updateDb: boolean): Promise<string[]> {
        if (updateDb) {
            this.appDb.updateOfflineData(offlineData);
        }
        this.dataVersion = offlineData?.Version;
        this.dataUpdateTime = offlineData?.UpdateTime;

        const photoIds = await this.appDb.getRequiredPhotoIds();
        const missingPhotoIds = await this.appDb.removeObsoleteAndGetMissingIds(photoIds);

        this.photosTotalRequired = photoIds.length;
        this.photosTotalStored = photoIds.length - missingPhotoIds.length;
        this.totalBadges = await this.appDb.getBadgeCount();

        return missingPhotoIds;
    }

    @action
    async downloadOfflineBadges(forceUpdate: boolean = false): Promise<number | null> {
        try {
            const offlineData = await this.apiService.getOfflineGateConfiguration(forceUpdate ? null : this.dataVersion);
            if (offlineData) {
                await this.updateOfflineData(offlineData, true);
                if (this.settingsService.automaticPhotoDownload) {
                    setTimeout(async () => await this.downloadOfflinePhotos());
                }
            }
            return offlineData?.Badges?.length || 0;
        } catch (err) {
            return null;
        }
    }

    async downloadOfflinePhotos(): Promise<void> {
        if (this.photosDownloadInProgess) {
            return;
        }
        try {
            this.photosDownloadInProgess = true;
            const photoIds = await this.appDb.getRequiredPhotoIds();
            const missingPhotoIds = await this.appDb.removeObsoleteAndGetMissingIds(photoIds);
            if (missingPhotoIds?.length) {
                for (const p of missingPhotoIds) {
                    let pd: Blob;
                    try {
                        pd = await this.apiService.getBadgePhoto(p);
                    } catch (e) {
                        continue;
                    }
                    await this.appDb.storePhoto(p, pd);
                    this.photosTotalStored = (await this.appDb.getStoredPhotoKeys()).length;
                }
            }
        } finally {
            this.photosDownloadInProgess = false;
        }
    }

    @action
    async deleteOfflineData(): Promise<void> {
        await this.updateOfflineData(null, true);
    }
    @action
    async clearHistory(clearSyncedOnly: boolean = true): Promise<void> {
        await this.appDb.clearHistory(clearSyncedOnly);
    }
    @action
    async clearAllPhotos(): Promise<void> {
        await this.appDb.clearAllPhotos();
        this.photosTotalStored = 0;
    }


    private async getPhotoBase64(photoId: string): Promise<string> {
        return await this.appDb.getPhotoBase64(photoId);
    }

    async checkBadgeOffline(request: ICheckRequest): Promise<ICheckResponse> {
        const badge = await this.appDb.getBadge(request.BadgeId);
        if (!badge) {
            return {
                IsValid: false,
                GeneratedDeclineReason: DeclineReason.BadgeDoesNotExist
            };
        }

        const declineReason = this.generateDeclineReson(badge, request.GateId);

        let image = null;
        try {
            image = await this.getPhotoBase64(badge.PhotoId);
        } catch (e) { }

        return {
            IsValid: declineReason == null,
            GeneratedDeclineReason: declineReason?.DeclineReason,
            OperationActionForDeclinationReason: declineReason?.OperationActionForDeclinationReason,
            Message: declineReason?.Message,
            Titles: badge.Titles,
            Images: image ? [image] : null
        };

    }

    private generateDeclineReson(badge: IOfflineBadge, gateId: string): IDeclineMessage {
        if (badge.State !== BadgeState.Active) {
            return { DeclineReason: DeclineReason.BadgeDeactivated };
        }

        const gate = badge?.Gates.find(i => i.GateId === gateId);
        if (!gate) {
            return { DeclineReason: DeclineReason.OfflineGateMissing };
        }
        if (!gate.IsValid) {
            return {
                DeclineReason: DeclineReason.MissingValidity,
                OperationActionForDeclinationReason: gate.OperationActionForDeclinationReason,
                Message: gate.InvalidMessage
            };
        }

        const currentTime = new Date();
        if (!gate.ValidityIntervals.length ||
            (
                gate.ValidityIntervals.some(i => i.From < currentTime && i.To > currentTime && i.IsValid)
                && !gate.ValidityIntervals.some(i => i.From < currentTime && i.To > currentTime && !i.IsValid))
        ) {
            return null;
        } else {
            return { DeclineReason: DeclineReason.NotValidDate };
        }

    }

}